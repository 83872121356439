import React, { useEffect, useState } from 'react'
import { COLUMNS } from './columns'
import './styles.css'
import { DragSwitch } from 'react-dragswitch'
import 'react-dragswitch/dist/index.css'

import { API, graphqlOperation } from 'aws-amplify'
import { createAforoMall, updateAforoMall, deleteAforoMall } from '../graphql/mutations'
import { listAforoMalls } from '../graphql/queries'

import { BoxLoading } from 'react-loadingg';

var lodash = require('lodash');

const initialState = { zoneId: '', name: '', codMall: '', rucMall: '', sendStatus: true }
const MINUTE_MS = 300000; // 5 min

export const HtmlTable = () => {
  const [aforoMalls, setAforoMalls] = useState([]);
  const [formState, setFormState] = useState(initialState);
  const [loading, setLoading] = useState(true);
  // const [status, setStatus] = useState(false);

  const handleClick = (item) => {
    setAforoMalls(prevState => {
      const filtered = prevState.filter(filteredItem => {
        return filteredItem.id !== item.id;
      });
      let res = [...filtered, {...item, sendStatus : !item.sendStatus}]
      updateMallStatus(item);
      return lodash.sortBy(res, ['name']);
    })
  }

  const handleDelete = (item) => {
    if (window.confirm('Desea borrar el registro?')) {
      deleteMall(item);
    }
  }

  function orderMalls(malls) {
    return lodash.sortBy(malls, ['name']);
  }

  useEffect(() => {
    fetchAforoMalls();

    const interval = setInterval(() => {
      fetchAforoMalls();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function fetchAforoMalls() {
    try {
      const mallData = await API.graphql(graphqlOperation(listAforoMalls))
      const malls = mallData.data.listAforoMalls.items
      const orderedMalls = orderMalls(malls);
      setAforoMalls(orderedMalls);
      setLoading(false);
    } catch (err) { console.log('error fetching aforo malls') }
  }

  async function updateMallStatus(mall) {
    const updatedData = {
      id: mall.id,
      sendStatus: !mall.sendStatus
    }

    try {      
      await API.graphql({ query: updateAforoMall, variables: {input: updatedData}})
    } catch (err) {
      alert('error updating mall status:', err)
    }
  }

  async function addMall() {
    try {
      setLoading(true);
      if (!formState.zoneId || !formState.name || !formState.codMall || !formState.rucMall) {
        alert('Todos los campos son obligatorios!');
        return
      }
      const mall = { ...formState };      
      setFormState(initialState);
      await API.graphql(graphqlOperation(createAforoMall, {input: mall}));
      fetchAforoMalls();
    } catch (err) {
      console.log(err)
      alert('error registering mall:', err);
      fetchAforoMalls();
    }
  }

  async function deleteMall(mall) {
    setLoading(true);
    const updatedData = {
      id: mall.id
    }

    try {      
      await API.graphql({ query: deleteAforoMall, variables: {input: updatedData}});
      fetchAforoMalls();
    } catch (err) {
      alert('error updating mall status:', err)
      fetchAforoMalls();
    }
  }

  return (
    <div className="container" style={styles.container}>
      <div className="form" style={styles.formContainer} >
        <div className="input-container" style={styles.inputContainer}>
          <div className="input-group" style={styles.inputGroup}>
            <input
              onChange={event => setInput('zoneId', event.target.value)}
              style={styles.input}
              value={formState.zoneId}
              placeholder="Id Zona"
            />
          </div>
          <div className="input-group" style={styles.inputGroup}>
            <input
              onChange={event => setInput('name', event.target.value)}
              style={styles.input}
              value={formState.name}
              placeholder="Nombre"
            />
          </div>
          <div className="input-group" style={styles.inputGroup}>
            <input
              onChange={event => setInput('codMall', event.target.value)}
              style={styles.input}
              value={formState.codMall}
              placeholder="Cod Establecimiento"
            />
          </div>
          <div className="input-group" style={styles.inputGroup}>
            <input
              onChange={event => setInput('rucMall', event.target.value)}
              style={styles.input}
              value={formState.rucMall}
              placeholder="RUC"
            />
          </div>
          <button className="save-mall" onClick={addMall}>Save Mall</button>
        </div>

      </div>
      <div className="table">
        <table className="styled-table">
          <thead>
            <tr>
              {COLUMNS.map(column => (<th>{column.Header}</th>))}
            </tr>
          </thead>
          <tbody>
            {loading ? <BoxLoading /> : aforoMalls.map(row => (
              <tr>
                <td>{row.name}</td>
                <td>{row.zoneId}</td>
                <td>{row.codMall}</td>
                <td>{row.rucMall}</td>
                <td>{row.ocupation}</td>
                <td>{row.ocupationLimit}</td>
                <td>{row.ocupation == null || row.ocupationLimit == null ? "-" : `${Math.round((row.ocupation/row.ocupationLimit) * 100)}%` }</td>
                <td>{row.sendingDate == null ? "-" : new Date(row.sendingDate).toLocaleString() }</td>
                <td>{<DragSwitch checked={row.sendStatus} onChange={() => handleClick(row)} />}</td>        
                <td><button className="delete-mall" style={styles.buttonDelete} onClick={() => handleDelete(row)}>Delete</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
    </div>
  )
}

const styles = {
  container: { display: 'flex', flexDirection: 'column', justifyContent: 'center' },
  formContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  inputContainer: { display: 'flex', flexDirection: 'row', margin: '0 auto' },
  inputGroup: { position: 'relative', borderBottom: '1px solid #e5e5e5', marginRight: 15 },
  input: { border: 'none', margin: 0, marginBottom: 10, padding: '9px 0', fontSize: 13, outline: 'none', width: '100%', fontFamily: 'inherit' }
}
