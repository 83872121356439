/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ATt3CJqIy",
    "aws_user_pools_web_client_id": "3ikaf1o99sec6bp3r6bckjijmt",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://k3hegbjmubdmrb3piayl4hp4pi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-g366vaiaszdn5aoup7dwhljqbq"
};


export default awsmobile;
