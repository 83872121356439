import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" hideSignUp={true}></AmplifySignIn>
      <div className='button-wrapper' style={{ width: '153px', position: 'absolute', marginLeft: '30px', marginTop: '10px' }}>
        <AmplifySignOut buttonText="Logout"></AmplifySignOut>
      </div>
    </AmplifyAuthenticator>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
