export const COLUMNS = [
  {
    Header: 'Mall',
  },
  {
    Header: 'ID Zona',
  },
  {
    Header: 'Cod Establecimiento',
  },
  {
    Header: 'RUC',
  },
  {
    Header: 'Ocupación',
  },
  {
    Header: 'Límite',
  },
  {
    Header: 'Aforo',
  },
  {
    Header: 'Fecha Envio',
  },
  {
    Header: 'Status',
  },
  {
    Header: '',
  }
]