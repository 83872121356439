/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAforoMall = /* GraphQL */ `
  query GetAforoMall($id: ID!) {
    getAforoMall(id: $id) {
      id
      zoneId
      name
      codMall
      rucMall
      ocupation
      ocupationLimit
      sendingDate
      sendStatus
      createdAt
      updatedAt
    }
  }
`;
export const listAforoMalls = /* GraphQL */ `
  query ListAforoMalls(
    $filter: ModelAforoMallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAforoMalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        zoneId
        name
        codMall
        rucMall
        ocupation
        ocupationLimit
        sendingDate
        sendStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
