import './App.css';
import Amplify from 'aws-amplify';
import awsExports from "./aws-exports";

import { HtmlTable } from './components/HtmlTable';
import { withAuthenticator } from '@aws-amplify/ui-react';

Amplify.configure(awsExports);


function App() {
  return (
    <div>
      <head>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </head>
      <div style={{ padding: '30px' }}>
        <HtmlTable />
      </div>
    </div>
  );
}

export default withAuthenticator(App);
