/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAforoMall = /* GraphQL */ `
  mutation CreateAforoMall(
    $input: CreateAforoMallInput!
    $condition: ModelAforoMallConditionInput
  ) {
    createAforoMall(input: $input, condition: $condition) {
      id
      zoneId
      name
      codMall
      rucMall
      ocupation
      ocupationLimit
      sendingDate
      sendStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateAforoMall = /* GraphQL */ `
  mutation UpdateAforoMall(
    $input: UpdateAforoMallInput!
    $condition: ModelAforoMallConditionInput
  ) {
    updateAforoMall(input: $input, condition: $condition) {
      id
      zoneId
      name
      codMall
      rucMall
      ocupation
      ocupationLimit
      sendingDate
      sendStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteAforoMall = /* GraphQL */ `
  mutation DeleteAforoMall(
    $input: DeleteAforoMallInput!
    $condition: ModelAforoMallConditionInput
  ) {
    deleteAforoMall(input: $input, condition: $condition) {
      id
      zoneId
      name
      codMall
      rucMall
      ocupation
      ocupationLimit
      sendingDate
      sendStatus
      createdAt
      updatedAt
    }
  }
`;
